<template>
  <div>
    <div class="header">
      <div class="header_top">
        <span style="margin: 0">当前位置：</span>
        <span class="center">合同管理</span>
        <img src="../../assets/right.png" alt />
        <span class="center">合同列表</span>
      </div>
      <div>
        <el-button
          v-access
          data-ctrl="contractAdd"
          @click="addObject"
          size="small"
          icon="el-icon-plus"
          type="primary"
          >新 建</el-button
        >
        <el-button
          @click="batchSigning"
          v-access
          data-ctrl="contractBatchSignList"
          size="small"
          icon="el-icon-plus"
          type="primary"
          >批量签署</el-button>
        <el-popover
          placement="top"
          width="160"
          style="margin-left: 10px;"
          v-model="exlexdialogVisible">
          <p>是否导出所有合同数据</p>
          <div style="text-align: right; margin: 0">
            <el-button size="mini" type="text" @click="exlexdialogVisible = false">取消</el-button>
            <el-button type="primary" size="mini" @click="exlehanden" :loading="listloading">确定</el-button>
          </div>
          <el-button
          slot="reference"
          @click="caontractExport"
          v-access
          data-ctrl="contractBatchSignList"
          size="small"
          type="primary"
        >
          导出数据
        </el-button>
</el-popover>
       
      </div>
    </div>

    <el-card class="box-card">
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 10px 0;
        "
      >
        <div style="display: flex; align-items: center; flex-wrap: wrap">
          <div>
            <span class="seach_text">合同名称：</span>

            <el-input
              size="mini"
              placeholder="请输入合同名称"
              v-model="formInline.f_like_name"
              style="margin-right: 10px; width: 200px"
              prefix-icon="el-icon-search"
              clearable
            ></el-input>
          </div>
          <div>
            <span class="seach_text">签署人：</span>

            <el-input
              size="mini"
              placeholder="请输入签署人"
              v-model="formInline.signerName"
              style="margin-right: 10px; width: 200px"
              prefix-icon="el-icon-search"
              clearable
            ></el-input>
          </div>
          <div>
            <span class="seach_text">用工企业名称：</span>
            <el-select
              remote
              reserve-keyword
              :remote-method="query"
              @change="getCity"
              v-model="formInline.workEnterpriseCode"
              clearable
              filterable
              placeholder="请输入企业名称"
              size="mini"
              style="margin-right: 10px"
            >
              <el-option
                v-for="item in eList"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </div>
          <div>
            <span class="seach_text">企业区域：</span>
            <el-select
              v-model="formInline.workEnterpriseAreaCode"
              @change="querycity"
              style="margin-right: 10px; width: 200px"
              filterable
              clearable
              size="mini"
              :placeholder="'请输入企业区域'"
            >
              <el-option-group
                v-for="group in areaSelectList"
                :key="group.provinceName"
                :label="group.provinceName"
              >
                <span style="color: red" slot="label">{{
                  group.provinceName
                }}</span>
                <el-option
                  v-for="item in group.citys"
                  :key="item.code"
                  :label="item.cityName"
                  :value="item.code"
                ></el-option>
              </el-option-group>
            </el-select>
          </div>

          <div>
            <span class="seach_text">企业门店：</span>
            <el-select
              remote
              reserve-keyword
              v-model="formInline.workEnterpriseStoreCode"
              clearable
              filterable
              placeholder="请输入企业门店"
              size="mini"
              style="margin-right: 10px"
            >
              <el-option label="全部" value=""></el-option>
              <el-option
                v-for="item in storeList"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </div>
          <!-- <div>
            <span class="seach_text">合同分类：</span>
            <el-select size="mini"
                       v-model="formInline.type"
                       placeholder="请选择合同分类"
                       clearable>
              <el-option label="全部"
                         value></el-option>

              <el-option label="入职合同"
                         value="1"></el-option>
              <el-option label="离职合同"
                         value="2"></el-option>
              <el-option label="用工合同"
                         value="3"></el-option>
            </el-select>
          </div> -->
          <el-button
            size="small"
            style="margin: 10px; border-radius: 5px"
            type="primary"
            @click="seach"
            >查询</el-button
          >
        </div>
        <div></div>
      </div>
      <el-table :data="list" style="width: 100%">
        <el-table-column label="合同名称" width="250">
          <template slot-scope="scope">
            <div class="clickColor" @click="goDetail({ code: scope.row.code })">
              {{ scope.row.name }}
            </div>
          </template>
        </el-table-column>

        <el-table-column label="签署主体" show-overflow-tooltip>
          <template slot-scope="scope">
            <div v-for="(item, index) in scope.row.signers" :key="index">
              <span>{{ index | subjectTypeVal }}：</span>
              <span>{{ item.subjectName || "未设置" }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="签署人">
          <template slot-scope="scope">
            <div v-for="(item, index) in scope.row.signers" :key="index">
              <div>
                <span>{{ item.signerName || "未设置" }}</span>
                <i
                  class="iconfont"
                  :class="{
                    iconclock:
                      scope.row.signersState[index] === '3' ||
                      scope.row.signersState[index] === '101',
                    iconsuccess: scope.row.signersState[index] === '2',
                    iconreeor: scope.row.signersState[index] === '4',
                  }"
                ></i>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="用工企业">
          <template slot-scope="scope">
            <span v-if="scope.row.workEnterprise">{{
              scope.row.workEnterprise.name
            }}</span>
            <span v-else>暂无</span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="合同分类">
          <template slot-scope="scope">
            <span v-if=" scope.row.type==='1'">入职合同</span>
            <span v-if=" scope.row.type==='2'">离职合同</span>
            <span v-if=" scope.row.type==='3'">用工合同</span>
          </template>
        </el-table-column> -->
        <el-table-column label="签署状态">
          <template slot-scope="scope">
            <span v-if="scope.row.signFlowStatus === '2'">签署完成</span>
            <span v-if="scope.row.signFlowStatus === '100'">待发起</span>
            <span v-if="scope.row.signFlowStatus === '101'">签署中</span>
            <span v-if="scope.row.signFlowStatus === '103'">已完成</span>
            <span v-if="scope.row.signFlowStatus === '104'">审核不通过</span>
            <span v-if="scope.row.signFlowStatus === '105'">待企业签署</span>
            <span v-if="scope.row.signFlowStatus === '107'">撤销</span>
            <span v-if="scope.row.signFlowStatus === '3'">失败</span>
            <span v-if="scope.row.signFlowStatus === '5'">已过期</span>
            <span v-if="scope.row.signFlowStatus === '7'">拒签</span>
          </template>
        </el-table-column>
        <el-table-column label="发起时间">
          <template slot-scope="scope">
            {{ scope.row.createTime | dateVal }}
          </template>
        </el-table-column>

        <el-table-column label="企业区域&&门店">
          <template slot-scope="scope">
            <p v-if="scope.row.area">
              {{ scope.row.area.provinceName }}-{{ scope.row.area.cityName }}
            </p>
            <p v-else>暂无</p>
            <span v-if="scope.row.store">{{ scope.row.store.name }}</span>
            <span v-else>暂无</span>
          </template>
        </el-table-column>

        <el-table-column label="操作">
          <template slot-scope="scope">
            <div
              class="shuxian"
              v-access
              data-ctrl="contractFlowStart"
              v-show="
                scope.row.signer.length && scope.row.signFlowStatus === '100'
              "
            >
              <el-button
                :loading="scope.row.startContractLoading"
                @click="startFlow(scope.row)"
                type="text"
                >发起</el-button
              >
            </div>
            <div
              class="shuxian"
              v-if="
                scope.row.signFlowStatus=== '101' 
              "
            >
              <el-button
                :loading="scope.row.getSignUrlLoading"
                @click="getSignUrl(scope.row)"
                type="text"
                >签署</el-button
              >
            </div>
            <div class="shuxian" v-if="scope.row.signFlowStatus=== '101'">
              <el-button
                :loading="scope.row.revokeLoading"
                @click="getRevoke(scope.row)"
                type="text"
                >撤销</el-button
              >
            </div>
            <div
              class="shuxian"
              v-access
              data-ctrl="contractDel"
              v-if="
                scope.row.signFlowStatus === '100' ||
                scope.row.signFlowStatus === '7'
              "
            >
              <el-popover
                placement="top"
                width="400"
                trigger="manual"
                :ref="'popover-' + scope.row.id"
              >
                <div class="del-pop">
                  <img src="../../assets/del.png" alt />
                </div>
                <div class="del-title">删除合同</div>
                <div class="del-sumtitle">是否确认删除本条合同记录？</div>
                <div class="del-btn">
                  <el-button type="primary" @click="del(scope.row)" plain
                    >确认</el-button
                  >
                  <el-button type="info" @click="doCancel(scope.row.id)" plain
                    >取消</el-button
                  >
                </div>
                <el-button
                  type="text"
                  slot="reference"
                  @click="pOpen(scope.row.id)"
                  >删除</el-button
                >
              </el-popover>
            </div>
            <div class="shuxian" v-show="scope.row.signFlowStatus === '5'">
              <el-divider
                direction="vertical"
                v-show="scope.row.signFlowStatus === '5'"
              ></el-divider>
              <el-popconfirm
                title="该合同延期功能，只可延期一次，为90天"
                @confirm="getContractDelay(scope.row)"
              >
                <el-button
                  slot="reference"
                  :loading="scope.row.startContractLoading"
                  type="text"
                  >延期</el-button
                >
              </el-popconfirm>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <div class="pagination">
      <span class="clickColor">共{{ total }}条</span>
      <span class="clickColor" style="margin: 0 10px"
        >每页{{ pageSize }}条</span
      >
      <el-pagination
        background
        :current-page.sync="pageNumber"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        prev-text="上一页"
        next-text="下一页"
        layout="prev,pager, next,jumper"
        :total="total"
      ></el-pagination>
    </div>

    <addDialog
      :addDialogVisible.sync="addDialogVisible"
      @func="close()"
    ></addDialog>

    <el-dialog
      title="批量签署合同"
      :visible.sync="batchSigningShow"
      :before-close="handleClose"
    >
      <el-table
        style="margin-top: 10px"
        :data="gridData"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column property="title" label="合同名称"></el-table-column>
        <el-table-column label="甲方">
          <template slot-scope="scope">
            <span
              v-for="(item, index) in scope.row.signers.orgSigner"
              :key="index"
              >{{ item.orgName }}-{{ item.transactor }}</span
            >
          </template>
        </el-table-column>
        <el-table-column label="乙方">
          <template slot-scope="scope">
            <span
              v-for="(item, index) in scope.row.signers.psnSigner"
              :key="index"
              >{{ item }}</span
            >
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          :disabled="dispathShown"
          @click="batchSigningBtn()"
          >签 署</el-button
        >
        <el-button @click="batchSigningShow = false">取 消</el-button>
      </span>
    </el-dialog>
    
  </div>
</template>

<script>
import moment from 'moment';
// import * as _ from 'lodash';
//接口：
import { api } from '/src/api/base';

// 导入导出文件js
import { parseJsonToTable } from '/src/lib/dev';

import { stetudata } from './utils';
//新增接口

//获取签署列表
export const contractBatchSignList = api()('contract.batchSignList.json');
export const contractDelay = api()('contract.delay.json');

export const contractbatchSignUrl = api()('contract.batchSignUrl.json');
export const contractPersionAuth = api()('contract.persionAuth.json');
export const contractOrgAuth = api()('contract.orgAuth.json');

//删除接口
export const ContractDel = api()('contract.del.json');
//合同作废

//合同签署完成
export const ContractComplete = api()('contract.complete.json');
//查询列表接口
export const ContractList = api('/contract')('contract.list.json');
//查询详情接口

//编辑接口
export const workEnterpriseStoreList = api()(
  '/work_enterprise_store.list.json'
);
export const workEnterpriseList = api()('work_enterprise.list.json');
export const workEnterpriseAreaList = api()('work_enterprise_area.list.json');
export const workEnterpriseAreaContainArea = api()(
  'work_enterprise_area.containArea.json'
);
export const ContractFlowStart = api()('contract.flow.start.json');
// 批量签署
export const ContractSignUrlDescribe = api('')('contract.signUrl.batch.json');
// 单个签署
export const contractSignUrlSingle = api('')('contract.signUrl.single.json');
// 导出接口
export const ContarctExport = api('/contractListAll')(
  'contract.list.all.json'
);
// 撤销
export const revokehandle = api('/contractRevoke')('contract.revoke.json');
// this.$api('', {
//           signFlowId: code,
//           userCode: res.code,
//           redirectUrl: `${window.location.origin}/weixin/pay/content`
//         })
export default {
  components: {
    addDialog: () => import('./Add'),
  },
  data() {
    return {
      getSignUrlLoading: false,
      startContractLoading: false,
      batchSigningShow: false,
      revokeLoading: false,
      exlexdialogVisible:false,
      listloading:false,  // 导出loading
      value1: [
        moment(new Date()).subtract(2, 'months').format('YYYY-MM-DD'),
        moment(new Date()).format('YYYY-MM-DD'),
      ],
      gridData: [],
      selectList: [],
      areaSelectList: [],
      storeList: [],
      eList: [],
      pageSize: 10,
      pageNumber: 1,
      total: 0,
      formInline: {
        f_like_name: '',
        signerName: '',
        type: '',
        workEnterpriseAreaCode: '',
        workEnterpriseCode: '',
        workEnterpriseStoreCode: '',
      },
      list: [],
      addDialogVisible: false,
      dispathShown: true,
      isEnterpriseAdmin: null,
      dialogData: null,
      exportData: [],
    };
  },

  created() {
    // const queryParams = this.$route.query;
    // if (queryParams.state) {
    //   this.formInline.state = queryParams.state;
    //   console.log(this.formInline.state);
    // }
    // if (queryParams.deadTime && queryParams.deadTime === '1') {
    //   this.formInline.minSignDeadline = moment().format('YYYY-MM-DD HH:mm:ss');
    //   this.formInline.maxSignDeadline = moment()
    //     .endOf('day')
    //     .format('YYYY-MM-DD HH:mm:ss');
    // }
    // if (queryParams.deadTime && queryParams.deadTime === '2') {
    //   this.formInline.minSignDeadline = moment().format('YYYY-MM-DD HH:mm:ss');
    //   this.formInline.maxSignDeadline = moment(
    //     moment()
    //       .endOf('day')
    //       .valueOf() +
    //     7 * 24 * 60 * 60 * 1000,
    //   ).format('YYYY-MM-DD HH:mm:ss');
    // }
    // if (queryParams.deadTime && queryParams.deadTime === '3') {
    //   this.formInline.minSignDeadline = moment().format('YYYY-MM-DD HH:mm:ss');
    //   this.formInline.maxSignDeadline = moment([
    //     moment().year(),
    //     moment().month() + 1,
    //     moment().date(),
    //   ])
    //     .endOf('day')
    //     .format('YYYY-MM-DD HH:mm:ss');
    // }
    // if (queryParams.deadTime && queryParams.deadTime === '4') {
    //   this.formInline.maxSignDeadline = moment().format('YYYY-MM-DD HH:mm:ss');
    // }
    this.isEnterpriseAdmin = window.localStorage.getItem('isEnterpriseAdmin');

    this.loadData();
    this.enterpriseList();
  },
  methods: {
    // 导出数据
    caontractExport() {
      this.exlexdialogVisible = true;
    },
    exlehanden() {
      this.listloading = true
      ContarctExport(this.formInline).then((res) => {
        console.log(res,66)
        this.exportData = res.list.map((it) => {
          let reslsit = {
            name: it.name,
            subjectName: `甲方:${it.signer[0].orgName} 乙方:${it.signer[1].psnName}`,
            signersignName: `甲方:${it.signer[0].psnName} 乙方:${it.signer[1].psnName}`,
            workEnterprise: it.workEnterprise.name,
            status: stetudata(it.signFlowStatus),
            idCardNumber:it.singerInfo.idCardNumber,
            username: it.singerInfo.username,
            createTime: it.createTime,
            address: `${it.area.cityName}-${
              it.store == null ? '' : it.store.name
            }`,
          };

          return reslsit;
        });
        this.exlexdialogVisible = false
        this.listloading = false
        parseJsonToTable(
          '合同列表数据',
          {
            合同名称: 'name',
            签署主体: 'subjectName',
            签署人: 'signersignName',
            用工企业: 'workEnterprise',
            签署状态: 'status',
            乙方身份证号码: 'idCardNumber',
            乙方手机号: 'username',
            发起时间: 'createTime',
            企业区域门店: 'address',
          },
          this.exportData,
          true
        );
      });
    },
    // 撤销
    getRevoke(row) {
      this.$prompt('撤销原因', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        if (value.length > 10) {
          this.$message({
            type: 'error',
            message: '撤销原因不能超过20个字符',
          });
        }
        revokehandle({
          signFlowId: row.signFlowId,
          revokeReason: value,
        }).then(() => {
          this.$message({
            type: 'success',
            message: '撤销成功',
          });
          this.loadData();
        });
      });
    },
    query(val) {
      this.pageNumber = 1;
      this.enterpriseList(val);
    },
    getContractDelay(row) {
      const params = {
        signFlowId: row.signFlowId,
      };
      contractDelay(params).then(() => {
        this.$message({
          type: 'success',
          message: '延期成功',
        });
        this.getList();
        this.loadData();
      });
    },
    getCity(val) {
      if (val) {
        const params = {
          workEnterpriseCode: val,
        };
        workEnterpriseAreaContainArea(params).then((res) => {
          this.areaSelectList = res;
        });
      } else {
        this.areaSelectList = [];
        this.storeList = [];
        this.formInline.workEnterpriseAreaCode = '';
        this.formInline.workEnterpriseAreaCode = '';
      }
    },
    querycity(val) {
      if (val) {
        workEnterpriseStoreList({
          workEnterpriseAreaCode: val,
          pageNumber: 1,
          pageSize: 1000,
        }).then((res) => {
          this.storeList = res.list;
        });
      } else {
        this.areaSelectList = [];
      }
    },
    enterpriseList(val) {
      const params = {
        keywords: val,
        pageNumber: 1,
        pageSize: 1000,
      };
      workEnterpriseList(params).then((res) => {
        this.eList = res.list;
      });
    },
    async batchSigning() {
      this.batchSigningShow = true;
      this.getList();
    },
    getList() {
      const params = {
        // redirectUrl: generatePath('web/system/contract/list')
        redirectUrl: window.location.href,
      };
      contractBatchSignList(params).then((res) => {
        if (res.authUrl) {
          window.location.href = res.authUrl;
        } else {
          this.gridData = res;
        }
      });
    },

    batchSigningBtn() {
      if (this.selectList.length > 0) {
        const params = {
          flowIds: this.selectList,
          redirectUrl: window.location.href,
        };
        contractbatchSignUrl(params).then((res) => {
          this.$message({
            type: 'info',
            message: res.message,
          });
          window.location.href = res.data.batchSignShortUrlWithoutLogin;
          // if (res.message.includes('获取批量签署链接失败: 签署人')) {
          //   this.$confirm(
          //     '签署人未授权免登录授权, 是否授权?',
          //     '提示',
          //     {
          //       confirmButtonText: '确定',
          //       cancelButtonText: '取消',
          //       type: 'warning',
          //     }
          //   )
          //     .then(() => {
          //       const params = {
          //         redirectUrl: window.location.href,
          //       }
          //       contractPersionAuth(params).then((res) => {
          //         window.location.href = res.data.batchSignShortUrlWithoutLogin
          //       })
          //     })
          //     .catch(() => {
          //       this.$message({
          //         type: 'info',
          //         message: '已取消授权',
          //       })
          //     })
          // } else if (
          //   res.message.includes('获取批量签署链接失败: 签署主体')
          // ) {
          //   this.$confirm(
          //     '企业未授权免登录授权, 是否授权?',
          //     '提示',
          //     {
          //       confirmButtonText: '确定',
          //       cancelButtonText: '取消',
          //       type: 'warning',
          //     }
          //   )
          //     .then(() => {
          //       const params = {
          //         redirectUrl: window.location.href,
          //       }
          //       contractOrgAuth(params).then((res) => {
          //         window.location.href = res.data
          //       })
          //     })
          //     .catch(() => {
          //       this.$message({
          //         type: 'info',
          //         message: '已取消授权',
          //       })
          //     })
          // } else if (res.message === '成功') {
          // window.location.href = res.data.batchSignShortUrlWithoutLogin

          // }
        });
      } else {
        this.$message({
          type: 'info',
          message: '请选择所要签署合同',
        });
      }
    },
    handleSelectionChange(val) {
      this.selectList = val.map((it) => it.signFlowId);

      this.dispathShown = !this.selectList.length;
    },
    handleClose() {
      this.batchSigningShow = false;
    },
    pOpen(id) {
      this.$refs['popover-' + id].doShow();
    },
    doCancel(id) {
      // console.log(this.$refs["popover-" + id]);
      this.$refs['popover-' + id].doClose();
    },
    seach() {
      this.pageNumber = 1;
      this.loadData();
    },
    async getSignUrl(row) {
      row.getSignUrlLoading = true;
      const url = await contractSignUrlSingle({
        signFlowId: row.signFlowId,
        userCode: window.localStorage.getItem('code'),
        redirectUrl: window.location.href,
      });

      window.location.href = url.url;
    },
    // 删除合同
    async del(row) {
      await ContractDel({
        code: row.code,
      });
      this.$message.success(`合同${row.name}删除成功！`);
      this.doCancel(row.id);

      this.loadData();
    },
    // 合同作废

    // 签署完成

    handleSizeChange(val) {
      this.pageSize = val;
      this.loadData();
    },
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.loadData();
    },
    loadData() {
      const params = {
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        ...this.formInline,
      };
      ContractList(params).then((res) => {
        // if (res.list.length != 0) {
        this.list = res.list.map((it) => {
          let a = [];
          let b = [];

          if (new Set(it.signer.map((m) => m.psnId)).size === 2) {
            a = it.signer.filter((i) => i.orgName != '');
            b = it.signer.filter((i) => i.orgName === '');
          }
          return {
            ...it,
            getSignUrlLoading: false,
            startContractLoading: false,
            signers: [
              {
                subjectName: a.length ? a[0].orgName : '',
                signerName: a.length ? a[0].psnName : '',
              },
              {
                subjectName: b.length ? b[0].psnName : '',
                signerName: a.length ? b[0].psnName : '',
              },
            ],
            signersState: [
              a.length ? a[0].signResult : '',
              a.length ? b[0].signResult : '',
            ],
          };
        });

        // }
        this.total = res.total;
        console.log(this.list);
      });
    },

    goBack() {
      window.history.go(-1);
    },
    addObject() {
      this.addDialogVisible = true;
    },
    goEdit(obj) {
      this.$router.push({
        path: '/system/contract/add',
        query: obj,
      });
    },
    goDetail(obj) {
      this.$router.push({
        path: '/system/contract/detail',
        query: obj,
      });
    },
    close() {
      this.loadData();
    },
    async startFlow(it) {
      it.startContractLoading = true;
      await ContractFlowStart({ code: it.code });

      this.loadData();
      it.startContractLoading = false;
      this.$message.success('发起成功');
    },
    putAway() {
      this.formInline.type = '';
      this.pageNumber = 1;
      this.loadData();
    },
  },
};
</script>

<style lang="scss" scoped>
.clickColor {
  color: #409eff;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.iconsuccess {
  color: #67c23a;
}

.iconreeor {
  color: #f56c6c;
}

.del-pop {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.del-title {
  font-size: 17px;
  text-align: center;
  color: #333333;
  margin: 15px 0;
}

.del-sumtitle {
  font-size: 13px;
  text-align: center;
  color: #a9a9a9;
  margin: 0 0 5px 0;
}

.table_border {
  margin: 20px 0;

  .table_top {
    width: 100%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    background: #4977fc;
    color: #fff;
    font-size: 14px;
  }

  .table_demo {
    padding: 35px 0;
    font-size: 10px;
  }
}
</style>
