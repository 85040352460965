export const parseSigner = data => {
  const subjectAccounts = data.subjectAccounts.split(',');
  const subjectNames = data.subjectNames.split(',');
  const subjectTypes = data.subjectTypes.split(',');
  const signerAccounts = data.signerAccounts.split(',');
  const signerNames = data.signerNames.split(',');
  const signerResults = data.signerResults.split(',');
  const signerStates = (
    data.signerStates ||
    Array.from(subjectAccounts)
      .map(() => '0')
      .join(',')
  ).split(',');
  const len = subjectAccounts.length;
  if (
    subjectNames.length != len ||
    subjectTypes.length != len ||
    signerAccounts.length != len ||
    signerNames.length != len
  ) {
    console.log('签署人配置有误');
  } else {
    return subjectAccounts.map((it, index) => {
      return {
        subjectAccount: it,
        subjectName: subjectNames[index],
        subjectType: subjectTypes[index],
        signerAccount: signerAccounts[index],
        signerName: signerNames[index],
        signerState: signerStates[index],
        signerResult: signerResults[index]
      };
    });
  }
};

export const stetudata= data=>{
  if(data==2){
    return '签署完成'
  }
  if(data==100){
    return '待发起'
  }
  if(data==101){
    return '签署中'
  }
  if(data==103){
    return '已完成'
  }
  if(data==104){
    return '审核不通过'
  }
  if(data==105){
    return '待企业签署'
  }
  if(data==3){
    return '失败'
  }
  if(data==5){
    return '已过期'
  }
  if(data==107){
    return '撤销'
  }
}

